<template lang="html" src="./pagePartQuotes.template.vue"></template>

<style lang="scss" src="./pagePartQuotes.scss"></style>

<script>
import { ApiErrorParser, DataTableFilter, CustomFilterForSearchDatatable } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./pagePartQuotes.i18n');

export default {
  name: 'PagePartQuotes',
  components: { DataTableFilter },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      headers: [],
      quoteList: [],
      loading: false,
      part: null,
      search: '',
      filterObject: {
        status: [
          {
            name: this.$t('QuoteLocked'),
            value: 'LOCKED',
            icon: 'fas fa-lock',
            iconColor: 'info',
            get selected() {
              return this.number > 0;
            },
            number: 0,
          },
          {
            name: this.$t('QuoteReady'),
            value: 'READY',
            icon: 'fas fa-clock',
            iconColor: 'warning',
            get selected() {
              return this.number > 0;
            },
            number: 0,
          },
          {
            name: this.$t('QuoteDeclined'),
            value: 'DECLINED',
            icon: 'fas fa-times-circle',
            iconColor: 'error',
            number: 0,
          },
          {
            name: this.$t('QuoteAccepted'),
            value: 'ACCEPTED',
            icon: 'fas fa-check-circle',
            iconColor: 'success',
            number: 0,
          },
        ]
      },
      statusFilter: [],
    };
  },
  created() {
    this.getBrandPart();
  },
  mounted() {
    this.headers = [
      {
        text: this.$t('Status'),
        value: 'status',
        width: '80px',
        align: 'center',
        filter: (value, search, item) => {
          if (this.statusFilter.length === 0) return true;
          if (this.statusFilter.includes(value) || (value === 'CLOSED' && this.statusFilter.includes('ACCEPTED'))) return true;
        }
      },
      {
        text: this.$t('QuoteID'),
        value: 'quoteNumber',
        align: 'center',
        width: '200px',
      },
      {
        text: this.$t('TotalAmount'),
        value: 'selling.totalAmount',
        align: 'right',
      },
      { text: this.$t('Created'),
        value: 'created',
        align: 'center',
      },
      {
        text: this.$t('Action'),
        value: 'action',
        align: 'center',
        sortable: false,
      },
    ];
  },
  methods: {
    customFilter(items, search, filter) {
      if (typeof items === 'number' ) {
        items = items.toString().replace('.', ',');
      }
      return CustomFilterForSearchDatatable.customFilter(items, search, this);
    },
    getBrandPart() {
      this.loading = true;
      this.$apiInstance
        .getBrandPart(this.$appConfig.currentBrand, this.$route.params.partUUID)
        .then(
          (partData) => {
            this.part = partData;
            this.getBrandPartQuotes();
          },
          /**
           * ERROR GET BRAND PART
           */
          (error) => {
            ApiErrorParser.parse(error);
          }
        );
    },
    getBrandPartQuotes() {
      /**
       * GET BRAND QUOTES
       */
      this.$apiInstance
        .getBrandPartQuotes(
          this.$appConfig.currentBrand,
          this.$route.params.partUUID
        )
        .then((data) => {
          this.quoteList = data;
          /**
           * Reset Filter Object
           */
          for (const key in this.filterObject) {
            for (const obj of this.filterObject[key]) {
              obj.number = 0;
            }
          }
          this.quoteList.forEach(quote => {
            this.filterObject.status.find(x => x.value === quote.status || (x.value === 'ACCEPTED' && quote.status === 'CLOSED')).number++;
          });
        })
        .catch((error) => {
          /**
           * ERROR GET BRAND QUOTES
           */
          ApiErrorParser.parse(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    refreshPage() {
      this.loading = true;
      this.getBrandPartQuotes();
    },
    updateFilters(updatedFilters) {
      this.statusFilter = updatedFilters.status;
    }
  },
};
</script>
